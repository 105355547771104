import './App.css';
import React, { useState, useEffect } from 'react';
// import LandingPage from './components/LandingPage/LandingPage';
import HoldingPage from './components/HoldingPage/HoldingPage';
import SchedulePage from './components/SchedulePage/schedulePage';
import LeaguePage from './components/League/League';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import SignInSignUpModal from './components/SignInSignUpModal/SignInSignUpModal';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
	signIn,
	signUp,
	googleSignIn,
	signOut,
	linkAccount,
} from './services/authService';
import AdminPage from './components/AdminPage/AdminPage';
import './custom-bootstrap.scss';
import Navbar from './components/Navbar/Navbar';
import FantasyPage from './components/Fantasy/FantasyMain';
import EditTeamPage from './components/EditTeam/EditTeamPage';
import AdminSportsPage from './components/AdminPage/AdminSportsPage';
import AdminEventsPage from './components/AdminPage/AdminEventsPage';
import AdminRidersPage from './components/AdminPage/AdminRidersPage';
import LoginPage from './components/LoginPage/Login';
import ResetPasswordPage from './components/LoginPage/ResetPassword';
import { getUser } from './services/userService';
import ForgotPassword from './components/LoginPage/ForgotPassword';
import AdminLeaguesPage from './components/AdminPage/AdminLeaguesPage';
import EditUserProfile from './components/ProfilePage/UserProfile';
import AdminSnapshotForm from './components/AdminPage/AdminSnapshots';
import HomePage from './components/HomePage/HomePage';
import VideoAdminPage from './components/AdminPage/AdminVideosPage';
import AdminResultsPage from './components/AdminPage/AdminResultPage';
import RankingPage from './components/RankingsPage/RankingPage';
import Footer from './components/Footer/Footer';
import PrivacyPolicyPage from './components/Footer/PrivacyPolicy';
import ContactUs from './components/Footer/ContactUs';
import AboutUsPage from './components/Footer/AboutUsPage';
import AdminUsersPage from './components/AdminPage/AdminUsersPage';
import { Row } from 'react-bootstrap';
import topAd from './assets/BannerAd_NonMobile_2.png';
import AdminAdManagerPage from './components/AdminPage/AdminAdManager';
import Watch from './components/WatchPage/Watch';
import AdminWatchManager from './components/AdminPage/AdminWatchManager';
import TermsComponent from './components/Footer/TermsAndConditions';
import ReactGA from 'react-ga4';
import AdminDisableManager from './components/AdminPage/AdminDisableManager';

function App() {
	ReactGA.initialize(process.env.REACT_APP_GA4_ID);

	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isGmailAccount, setIsGmailAccount] = useState(false);
	// const [isSignIn, setIsSignIn] = useState(true);
	// const [username, setUsername] = useState('');
	// const [email, setEmail] = useState('');
	// const [password, setPassword] = useState('');

	const handleSignIn = async (email, password) => {
		const userData = {
			email,
			password,
		};

		try {
			const res = await signIn(userData);
			if (res) {
				localStorage.setItem('userId', res.data.userId);
				localStorage.setItem('role', res.data.role);
				setIsLoggedIn(true);
			}

			return res;
		} catch (error) {
			console.error('Error signing in:', error);
			return false;
		}
	};

	const handleSignUp = async (username, email, password) => {
		try {
			const userData = {
				username,
				email,
				password,
			};

			const res = await signUp(userData);
			if (res) {
				localStorage.setItem('userId', res.data.userId);
				localStorage.setItem('role', res.data.role);
				setIsLoggedIn(true);
			}

			return res;
		} catch (error) {
			console.error('Error signing up:', error);
			return;
		}
	};

	const handleGoogleSignIn = async (credentialResponse) => {
		try {
			const res = await googleSignIn({ token: credentialResponse.credential });
			localStorage.setItem('userId', res.data.userId);
			localStorage.setItem('role', res.data.role);
			setIsLoggedIn(true);
		} catch (error) {
			console.error('Error signing in:', error);
		}
	};

	const handleCheckIfLoggedIn = async () => {
		try {
			const res = await getUser();
			if (res.status === 200) {
				setIsLoggedIn(true);
			}
		} catch (error) {
			console.error('Error checking if logged in:', error);
			setIsLoggedIn(false);
		}
	};

	useEffect(() => {
		handleCheckIfLoggedIn();
	}, []);

	const handleLogout = async () => {
		// Clear cookie for storage
		const res = await signOut();
		localStorage.clear();
		setIsLoggedIn(false);
		window.location.href = '/';
	};

	return (
		<Router>
			<GoogleOAuthProvider
				// @ts-ignore
				clientId={process.env.REACT_APP_CLIENT_ID}
			>
				<div className='bg-light' style={{ minHeight: '100vh' }}>
					{/* <Row className='m-0 p-0'>
						<img
							src={topAd}
							alt='Fantasy Banner'
							className='m-0 p-0'
							style={{ width: '100%', height: 'auto' }}
						/>
					</Row> */}
					<Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
					<Routes>
						<Route
							path='/login'
							element={
								<LoginPage
									handleSignIn={handleSignIn}
									handleSignUp={handleSignUp}
									handleGoogleSignIn={handleGoogleSignIn}
								/>
							}
						/>
						<Route path='/' element={<HoldingPage />} />
						{/* <Route path='/' element={<HomePage />} /> */}
						{/* <Route path="/home" element={<HomePage />} /> */}
						<Route path='/schedule' element={<SchedulePage />} />
						<Route path='/fantasy' element={<FantasyPage />} />
						<Route path='watch' element={<Watch />} />
						<Route path='/league/:leagueId' element={<LeaguePage />} />
						<Route
							path='/editprofile'
							element={<EditUserProfile isGmailAccount={isGmailAccount} />}
						/>
						<Route
							path='/editTeam/:teamId?/:leagueId?/:sport?/:series?'
							element={<EditTeamPage />}
						/>
						<Route
							path='/resetpassword/:token'
							element={<ResetPasswordPage />}
						/>
						<Route path='about' element={<AboutUsPage />} />
						<Route path='/rankings' element={<RankingPage />} />
						<Route path='/forgotpassword' element={<ForgotPassword />} />
						<Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
						<Route path='/termsandconditions' element={<TermsComponent />} />
						<Route path='contact' element={<ContactUs />} />
						<Route path='/admin' element={<AdminPage />} />
						<Route path='/admin/riders' element={<AdminRidersPage />} />
						<Route path='/admin/teams' element={<AdminPage />} />
						<Route path='/admin/leagues' element={<AdminLeaguesPage />} />
						<Route path='/admin/events' element={<AdminEventsPage />} />
						<Route path='/admin/results' element={<AdminResultsPage />} />
						<Route path='/admin/sports' element={<AdminSportsPage />} />
						<Route path='/admin/snapshots' element={<AdminSnapshotForm />} />
						<Route path='/admin/videos' element={<VideoAdminPage />} />
						<Route path='/admin/users' element={<AdminUsersPage />} />
						<Route path='/admin/admanager' element={<AdminAdManagerPage />} />
						<Route path='/admin/watch' element={<AdminWatchManager />} />
						<Route path='/admin/disable' element={<AdminDisableManager />} />
					</Routes>
				</div>
				<Footer />
			</GoogleOAuthProvider>
		</Router>
	);
}

export default App;
