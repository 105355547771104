import React, { useState } from 'react';
import { Card, Form, Button, Spinner } from 'react-bootstrap';
import './HoldingPage.css';
import logo from '../../assets/TRC_NEON.png';
import { Helmet } from 'react-helmet';
import { signUp } from '../../services/mailChimpService';

const HoldingPage = () => {
	const [showSignUp, setShowSignUp] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmitEmail = async () => {
		try {
			setIsLoading(true);
			const res = await signUp({ email: email });
			if (res.status == 201) {
				setShowThankYou(true);
				setIsLoading(false);
			}
		} catch (error) {
			console.log(error);
			setShowThankYou(true);
			setIsLoading(false);
		}
	}

	return (
		<div className="background-image">
			<Helmet>
				<title>Race Companion</title>
				<meta name="description" content="Register for Early Access" />
			</Helmet>
			<div className="dark-mask">
				<div className="d-flex justify-content-center align-items-center vh-100">
					<Card style={{ width: '18rem', border: 'none', backgroundColor: 'rgba(0,0,0,0.7)', }}>
						<Card.Body>
							<Card.Title>
								<div className="d-flex flex-row align-items-center justify-content-center">

									<img src={logo} alt="logo" style={{ maxWidth: '15rem', height: 'auto' }} />
								</div>
							</Card.Title>
							<div className="mt-4 d-flex flex-row align-items-center justify-content-center mb-1">
								{
									showThankYou ?
										<div className='col'>
											<h4 className="text-center text-white">Thank you for signing up! </h4>
											<h6 className="text-center text-white"> Stay tuned for exciting announcements. </h6>
										</div> :
										<>
											{
												!showSignUp ?
													<Button variant="primary" className='w-100' onClick={() => { setShowSignUp(true); console.log("HERE") }}>
														<b>
															REGISTER FOR EARLY ACCESS
														</b>
													</Button> :
													<Form>
														<Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
														<Button variant="primary" className="mt-2 w-100" onClick={handleSubmitEmail}>
															{!isLoading? <b>Sign up</b>
															: <Spinner size="sm" variant="light" />
															}
														</Button>
														<div className="mt-2 ms-1" style={{ color: 'gray', fontSize: 'small' }}>
															By signing up, you'll get the latest Race Companion updates.
														</div>
													</Form>
											}
										</>
								}
							</div>
						</Card.Body>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default HoldingPage;
